import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchClassificationOneOffSetting,
  fetchWebClientSettings,
  getAllProjectsByUserId,
  getProjectSection,
  SyncWebClientData,
} from "../../apis";
import SurveyCreatorComponent from "../../components/Survey";
import AnonymousHeader from "../../components/AnonymousHeader";
import loader from "../../assets/Markit-Survey-Logo1.gif";
import { GlobalContext } from "../../global_context";
import { getDateTime, isDateInFuture, formatAMPM } from "../../utils";
import uuid from "react-uuid";
import styles from "./questionnaire.module.css";
import clsx from "clsx";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import RegionsModal from "../../components/RegionsModal";
import SurveyExpire from "../../components/SurveyExpire";
import {
  BICRUX_URL,
  QUESTIONNAIRE_RESULT,
  RESPONSE_SAVE_MESSAGE,
  TIME_OUT,
} from "../../constants";
import SurveyThanks from "../../components/SurveyThanks";
import TextDirectionView from "../../components/text-direction-view";
const LOADING_STATE = {
  initialLoading: false,
};
function Questionnaire() {
  const [alignment, setAlignment] = useState("right");
  const [questionnaireJson, setQuestionnaireJson] = useState([]);
  const [section, setSection] = useState([]);
  const [projectDetail, setProjectDetail] = useState();
  const [location, setLocation] = useState({});
  const [oneOffSetting, setOneOffSetting] = useState();
  const [closeQuestionnaire, setCloseQuestionnaire] = useState(false);
  const [startDate, setStartDate] = useState();
  const [openLoading, setOpenLoading] = useState({ ...LOADING_STATE });
  const { region, setLoading, auth, setRegionModal, logout, regionModalShow } =
    useContext(GlobalContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [webClientSetting, setWebClientSetting] = useState();
  const [surveyExpired, setSurveyExpired] = useState({
    showComponent: false,
    message: "",
    buttonText: "",
    backToHome: false,
    url: "",
    date: "",
  });
  const { initialLoading } = openLoading;

  useEffect(() => {
    if (projectDetail?.projectSettingId) {
      const getWebClientSettings = async (projectSettingId) => {
        try {
          handleLoading("initialLoading", true);
          const response = await fetchWebClientSettings(projectSettingId);
          console.log("fetchWebClientSettings", response);
          if (response.status === 200) {
            let datePassed = isDateInFuture(
              new Date(response?.data?.surveyExpireDateTime)
            );
            if (!datePassed && response?.data?.surveyExpire) {
              setSurveyExpired({
                date: response?.data?.surveyExpireDateTime
                  ? formatAMPM(new Date(response?.data?.surveyExpireDateTime))
                  : "",
                message: "Your Survey is Expired!",
                showComponent: true,
                buttonText: "Back to Home",
                backToHome: true,
                url: BICRUX_URL,
              });
            } else if (
              response?.data?.responseLimit &&
              Number(response?.data?.responseCount) >= 12
            ) {
              setSurveyExpired({
                date: "",
                message: "No longer accepting responses!",
                showComponent: true,
                buttonText: "Back to Home",
                backToHome: true,
                url: BICRUX_URL,
              });
            } else {
              if (!region) {
                setRegionModal(true);
              }
              setWebClientSetting({
                ...response.data,
                expireMinutes: Number(response?.data?.expireMinutes),
              });
            }
            // setRegionModal(true);
            handleLoading("initialLoading", false);
          }
        } catch (error) {
          handleLoading("initialLoading", false);
        }
      };
      getWebClientSettings(projectDetail?.projectSettingId);
    }
  }, [projectDetail, region]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      });
    } else {
      console.log("Not Available");
    }
  }, []);

  var times = [];
  var startTime;
  var endTime;
  var survey_start;
  var survey_end;
  // var { maxTimeToFinishPage } = questionnaireJson;

  var allQuestions = questionnaireJson?.pages
    ?.map(({ elements }) => elements)
    ?.map((element) => element)
    ?.map(({ name }) => name);

  allQuestions?.shift();

  useEffect(() => {
    if (webClientSetting) {
      const fetchData = async () => {
        setLoading(true);
        const res = await getProjectSection(id);
        if (res.status === 200) {
          setSection(res.data.sections[0]);
          setQuestionnaireJson(
            JSON.parse(res.data.sections[0].questionnaire.questionnaireJSON)
          );
          setStartDate(getDateTime());
          setLoading(false);
        }
      };
      fetchData();
    }
    // setRegionModal(true);
  }, [id, webClientSetting]);

  useEffect(() => {
    if (!region) {
      // setRegionModal(true);
    }
  }, [region]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await getAllProjectsByUserId(auth.id);
        if (res.status === 200) {
          let findProject = res?.data?.find((item) => item?.id === Number(id));
          if (findProject) {
            setProjectDetail(findProject);
          }
        }
      } catch (error) {}
    };
    fetchProjects();
  }, [id]);

  useEffect(() => {
    if (id) {
      getOneOffSetting(id);
    }
  }, [id]);

  const getOneOffSetting = async (projectId) => {
    try {
      const res = await fetchClassificationOneOffSetting(projectId);
      if (res.status === 200) {
        setOneOffSetting(res.data[0]);
      }
    } catch (error) {}
  };

  const handleLoading = (name, status) => {
    setOpenLoading((prev) => ({
      ...prev,
      [name]: status,
    }));
  };

  const onComplete = async (sender) => {
    // let keys = Object.keys(sender.data);
    // let values = Object.values(sender.data);
    // let result = [];
    // for (let a in keys) {
    //   result.push({
    //     question: keys[a],
    //     answer: values[a],
    //     time: maxTimeToFinishPage > 0 ? times[a] : null,
    //     type: allQuestions[a]?.map((el) => el)?.map(({ type }) => type),
    //   });
    // }
    // if (allQuestions?.length === result?.length) {
    // } else {
    //   for (let j in allQuestions) {
    //     if (
    //       !result?.map(({ question }) => question)?.includes(allQuestions[j])
    //     ) {
    //       result.push({
    //         question: allQuestions[j],
    //         answer: null,
    //         time: maxTimeToFinishPage > 0 ? times[j] : null,
    //         type: allQuestions[j]?.map((el) => el)?.map(({ type }) => type),
    //       });
    //     }
    //   }
    // }

    // const answerJSON = JSON.stringify(sender.data);
    const answerJSON = sender.data;
    // const answerResult = getAnswerResult(sender, times);

    survey_end = new Date();

    let body = {
      visitMonth: oneOffSetting?.name,
      fieldStartDate: oneOffSetting?.fieldStartDate,
      fieldEndDate: oneOffSetting?.fieldEndDate,
      guid: uuid()?.replaceAll("-", ""),
      userId: auth?.id,
      projectId: id,
      sectionId: section?.id,
      subAreaName: region?.subArea?.name,
      subAreaId: region?.subArea?.id,
      countryId: region?.subArea?.countryId,
      stateId: region?.subArea?.stateId,
      cityId: region?.subArea?.cityId,
      superAreaId: region?.subArea?.superAreaId,
      areaId: region?.subArea?.areaId,
      level: region?.subArea?.level,
      lat: location.lat,
      lng: location.long,
      LOI:
        (new Date(survey_end).getTime() - new Date(survey_start).getTime()) /
        1000,
      startDateTime: startDate,
      endDateTime: getDateTime(),
      answerJSON: answerJSON,
      // answerResult: JSON.stringify(answerResult),
      questionnaireId: section?.questionnaire?.id,
      questionnaireVersion: section?.questionnaire?.version,
    };

    let online = window.navigator.onLine;
    if (online) {
      await saveDataOnline(body);
    } else {
      await storeOffline(body);
    }

    // if (webClientSetting?.offlineSupport) {
    //   let online = window.navigator.onLine;
    //   if (online) {
    //     await saveDataOnline(body);
    //   } else {
    //     await storeOffline(body);
    //   }
    // } else {
    //   await saveDataOnline(body);
    // }

    // await saveDataOnline(body);
    // let online = window.navigator.onLine;
    // if (online) {
    //   await saveDataOnline(body);
    // } else {
    //   await storeOffline(body);
    // }
    // debugger;
    // fetch("https://www.google.com/")
    //   .then((res) => {
    //     saveDataOnline(body);
    //   })
    //   .catch((err) => {
    //     if (webClientSetting?.offlineSupport) {
    //       storeOffline(body);
    //     }
    //   });
  };

  const saveDataOnline = async (body) => {
    setLoading(true);
    const res = await SyncWebClientData(body);
    if (res.status === 200) {
      setTimeout(() => {
        navigate("/projects");
      }, TIME_OUT);
      setLoading(false);
      toast.success(
        <span style={{ fontSize: "11px", color: "black" }}>
          {RESPONSE_SAVE_MESSAGE}
        </span>,
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
      // alert("Data saved!");
      handleRemoveQuestionnaireResult();
    } else {
      setLoading(false);
    }
  };

  const onQuestionTimeStart = (e) => {
    startTime = new Date();
  };

  const onQuestionTimeEnd = (e) => {
    endTime = new Date().getTime();
    var seconds = (endTime - startTime) / 1000;
    times.push(seconds);
  };

  const onSurveyStart = () => {
    survey_start = new Date();
  };

  const storeOffline = async (body) => {
    setLoading(true);
    localStorage.setItem("local_d", JSON.stringify(body));
    handleRemoveQuestionnaireResult();
    setTimeout(() => {
      navigate("/projects");
    }, TIME_OUT);
    setLoading(false);
  };

  const handleRemoveQuestionnaireResult = () => {
    localStorage.removeItem(QUESTIONNAIRE_RESULT);
    setCloseQuestionnaire(true);
  };

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return "Expired";
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const handleExpiredQuestionnaire = () => {
    let item = localStorage.getItem(QUESTIONNAIRE_RESULT);
    let parse = JSON.parse(item);
    setCloseQuestionnaire(true);
    if (item) {
      onComplete({ data: parse?.answerJSON });
    } else {
      onComplete({ data: {} });
    }
  };
  return (
    <div className="d-flex flex-column">
      <RegionsModal show={regionModalShow} />
      {surveyExpired?.showComponent ? (
        <SurveyExpire
          message={surveyExpired?.message}
          url={surveyExpired?.url}
          backToHome={surveyExpired?.backToHome}
          buttonText={surveyExpired?.buttonText}
          date={surveyExpired?.date}
        />
      ) : (
        <>
          <AnonymousHeader />
          {!initialLoading ? (
            <>
              {/* {region != null ? null : (
      <div className="alert alert-warning p-1" role="alert">
        No region has been selected!{" "}
        <button className="btn" onClick={() => setRegionModal(true)}>
          Select Regio
        </button>
      </div>
    )} */}

              <div
                className={clsx(
                  "container-fluid sticky-top p-1 px-3",
                  styles.main
                )}
              >
                {webClientSetting ? (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12 col-xs-12 justify-content-between d-flex">
                      <div className="p-1">
                        {webClientSetting?.logo ? (
                          <img
                            className={clsx("", styles.logo)}
                            alt="project-logo"
                            src={webClientSetting?.logo}
                          />
                        ) : (
                          ""
                        )}

                        <span className="m-2">
                          <span>{projectDetail?.projectName}</span>
                        </span>
                      </div>
                      <div
                        className="text-end d-flex"
                        style={{ paddingTop: "3px" }}
                      >
                        {webClientSetting?.calculateResponseTime &&
                        webClientSetting?.expireMinutes &&
                        !closeQuestionnaire ? (
                          <Countdown
                            date={
                              Date.now() +
                              Number(webClientSetting?.expireMinutes) * 60000
                            }
                            intervalDelay={0}
                            precision={3}
                            renderer={renderer}
                            onComplete={() => handleExpiredQuestionnaire()}
                          />
                        ) : (
                          ""
                        )}
                        {webClientSetting?.calculateResponseTime &&
                        webClientSetting?.expireMinutes &&
                        closeQuestionnaire ? (
                          <span>
                            {" "}
                            <span id="demo">Expired</span>
                          </span>
                        ) : (
                          ""
                        )}
                        {webClientSetting?.calculateResponseTime ? (
                          <span className={styles.line}></span>
                        ) : (
                          ""
                        )}
                        <span>
                          <TextDirectionView
                            alignment={alignment}
                            setAlignment={setAlignment}
                          />
                        </span>
                        <Button
                          color="inherit"
                          style={{
                            position: "relative",
                            top: "-2px",
                            fontSize: "14px",
                            textTransform: "capitalize",
                            fontFamily: "Montserrat",
                          }}
                          onClick={logout}
                        >
                          Log Out
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* <div
      style={{ paddingTop: "10px" }}
      className="container-fluid sticky-top bg-white"
    >
      {webClientSetting ? (
        <div className="row">
          <div className="col-md-1">
            {webClientSetting?.logo ? (
              <img
                className={clsx("", styles.logo)}
                alt="project-logo"
                src={webClientSetting?.logo}
              />
            ) : (
              ""
            )}
          </div>
          <div className="col-md-10">
            <h5 className="text-center">{projectDetail?.projectName}</h5>
          </div>
          <div className="col-md-1 text-end">
            {webClientSetting?.calculateResponseTime &&
            webClientSetting?.expireMinutes &&
            !closeQuestionnaire ? (
              <Countdown
                date={
                  Date.now() +
                  Number(webClientSetting?.expireMinutes) * 60000
                }
                intervalDelay={0}
                precision={3}
                renderer={renderer}
                onComplete={handleExpiredQuestionnaire}
              />
            ) : (
              ""
            )}
            {webClientSetting?.calculateResponseTime &&
            webClientSetting?.expireMinutes &&
            closeQuestionnaire ? (
              <h5 id="demo">Expired</h5>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div> */}

              <div className="container-fluid">
                <div className="row">
                  <div
                    className="col-md-12"
                    style={{ direction: alignment === "right" ? "rtl" : "ltr" }}
                  >
                    {!closeQuestionnaire ? (
                      <SurveyCreatorComponent
                        handleStart={onQuestionTimeStart}
                        handleSetQuestion={onQuestionTimeEnd}
                        isEdit={true}
                        handleStarted={onSurveyStart}
                        handleComplete={onComplete}
                        onCompletingHandle={onQuestionTimeEnd}
                        questionnaireJson={questionnaireJson}
                        webClientSetting={webClientSetting}
                        closeQuestionnaire={closeQuestionnaire}
                      />
                    ) : webClientSetting?.surveyExpireMessage ? (
                      <SurveyThanks
                        message={webClientSetting?.surveyExpireMessage}
                        date={new Date()}
                        projectName={projectDetail?.projectName}
                        url={BICRUX_URL}
                        id={id}
                        takeSuveyAgain={false}
                      />
                    ) : (
                      // <div className={styles.message}>
                      //   {webClientSetting?.surveyExpireMessage}
                      // </div>
                      ""
                    )}
                    {/* {
            webClientSetting?.surveyExpireMessage ? (
              <div className={styles.message}>
                {webClientSetting?.surveyExpireMessage}
              </div>
            ) : (
              ""
            )
          } */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "90vh" }}
            >
              <img alt="loading" style={{ maxHeight: 100 }} src={loader} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default Questionnaire;
